import React from "react";

import DarkTitle from "../../DarkTitle";

export default function Annealing() {
  return (
    <div>
      <DarkTitle
        title="Annealing and Cold End"
        bgImage="/images/FloatGlass/AnnealingLehrHeader.jpg"
        // tagline="float glass/Annealing and Cold End"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Once the glass ribbon leaves the tin bath, it has to be cooled
                before it can be cut and stored. This process requires strict
                temperature control and is done within an annealing lehr. The
                annealing lehr is divided into multiple zones with each zone
                having its own air blowing system to cool the glass. As the
                glass enters the annealing lehr, it is conveyed through the
                different zones on a roller conveyor system and its temperature
                gradient is steadily controlled to minimise the stresses within
                the glass. By the time the ribbon leaves the lehr, it has been
                uniformly cooled down to room temperature and is ready to be
                cut. The annealed glass enters the cold end where a longitudinal
                cutter is used to cut the edges which have been in contact with
                the top rollers and a cross-cutter is used to cut the sheet into
                the required length.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                We will provide you with the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Complete lehr design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Complete cold end design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Roller design</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Blowing system design
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Electrical configuration
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Operation</p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
