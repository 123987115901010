import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import NotFoundPage from "./Components/NotFoundPage";
import Turnkey from "./Components/Turnkey";
import FloatGlass from "./Components/FloatGlass";
import TableWare from "./Components/TableWare";
import General from "./Components/General";
import Feasibility from "./Components/Feasibility";
import Urban from "./Components/pages/turnkey/Urban";
import "./App.css";
import ScrollToTop from "./Components/helpers/ScrolltoTop";
import FuranceDesign from "./Components/pages/turnkey/FurnaceDesign";
import EquipmentDesign from "./Components/pages/turnkey/EquipmentDesign";
import Utilities from "./Components/pages/turnkey/Utilities";
import HeatUp from "./Components/pages/turnkey/HeatUp";
import TrainingPrograms from "./Components/pages/turnkey/TrainingPrograms";
import Construction from "./Components/pages/turnkey/Construction";
import FurnaceOperation from "./Components/pages/turnkey/FurnaceOperation";
import BatchComposition from "./Components/pages/turnkey/BatchComposition";
import GlassForming from "./Components/pages/turnkey/GlassForming";
import Maintenance from "./Components/pages/turnkey/Maintenace";
import Reporting from "./Components/pages/turnkey/Reporting";
import Melting from "./Components/pages/floatglass/Melting";
import TinBath from "./Components/pages/floatglass/TinBath";
import Annealing from "./Components/pages/floatglass/Annealing";
import BatchPlant from "./Components/pages/floatglass/BatchPlant";
import CoatedGlass from "./Components/pages/floatglass/CoatedGlass";
import NitrogenAndHydrogenPlant from "./Components/pages/floatglass/NitrogenAndHydrogenPlant";
import Furnace from "./Components/pages/tableware/Furnace";
import BatchAndCulletComposition from "./Components/pages/general/BatchAndCulletComposition";
import FuranceOperation2 from "./Components/pages/general/FurnaceOperation";
import RefractorySelection from "./Components/pages/general/RefractorySelection";
import VendorSelection from "./Components/pages/general/VendorSelection";
import Techno from "./Components/pages/general/Techno";

const turnkeyPath = "/services/turnkey";
const floatglassPath = "/services/float-glass";
const tablewarePath = "/services/tableware";
const generalPath = "/services/general";

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/services/turnkey" element={<Turnkey />} />
          <Route
            path={`${turnkeyPath}/pre-production/feasibility`}
            element={<Feasibility />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/urban-and-civil-planning`}
            element={<Urban />}
          />
          s
          <Route
            path={`${turnkeyPath}/pre-production/furnace-design`}
            element={<FuranceDesign />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/equipment-design`}
            element={<EquipmentDesign />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/utilities`}
            element={<Utilities />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/construction`}
            element={<Construction />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/training-programs`}
            element={<TrainingPrograms />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/heat-up`}
            element={<HeatUp />}
          />
          <Route
            path={`${turnkeyPath}/pre-production/equipment-design`}
            element={<EquipmentDesign />}
          />
          <Route
            path={`${turnkeyPath}/post-production/furnace-operation`}
            element={<FurnaceOperation />}
          />
          <Route
            path={`${turnkeyPath}/post-production/batch-composition`}
            element={<BatchComposition />}
          />
          <Route
            path={`${turnkeyPath}/post-production/glass-forming`}
            element={<GlassForming />}
          />
          <Route
            path={`${turnkeyPath}/post-production/maintenance`}
            element={<Maintenance />}
          />
          <Route
            path={`${turnkeyPath}/post-production/reporting`}
            element={<Reporting />}
          />
          <Route path={`${floatglassPath}/melting`} element={<Melting />} />
          <Route path={`${floatglassPath}/tin-bath`} element={<TinBath />} />
          <Route
            path={`${floatglassPath}/annealing-and-cold-end`}
            element={<Annealing />}
          />
          <Route
            path={`${floatglassPath}/batch-plant`}
            element={<BatchPlant />}
          />
          <Route
            path={`${floatglassPath}/coated-glass`}
            element={<CoatedGlass />}
          />
          <Route
            path={`${floatglassPath}/nitrogen-and-hydrogen-plant`}
            element={<NitrogenAndHydrogenPlant />}
          />
          <Route
            path={`${generalPath}/batch-and-cullet-composition`}
            element={<BatchAndCulletComposition />}
          />
          <Route
            path={`${generalPath}/furnace-operation`}
            element={<FuranceOperation2 />}
          />
          <Route
            path={`${generalPath}/refractory-selection`}
            element={<RefractorySelection />}
          />
          <Route
            path={`${generalPath}/vendor-selection`}
            element={<VendorSelection />}
          />
          <Route path={`${generalPath}/techno-economic`} element={<Techno />} />
          <Route path={`${tablewarePath}/furnace`} element={<Furnace />} />
          <Route path="/services/float-glass" element={<FloatGlass />} />
          <Route path="/services/tableware" element={<TableWare />} />
          <Route path="/services/general" element={<General />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
