import React from "react";
import DarkTitle from "../../DarkTitle";
import ParallaxScrolling from "../../ParallaxScrolling";

const furnace = "/images/Tableware/Melting.jpg";

export default function FuranceDesign() {
  return (
    <div>
      <DarkTitle
        title="Furnace Design"
        // tagline="pre-production/furnace design"
        bgImage="/images/Turnkey/FurnaceDesign/FurnaceDesign.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                As the heart of any glass plant, it is absolutely essential to
                have a furnace design that is fit-for-purpose. By using our
                tried and tested database of drawings and incorporating the
                latest advancements in technology, we will provide you with
                detailed furnace drawings that will match all your melting
                requirements.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ParallaxScrolling bgImage={furnace} />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Decision Making
              </h5>
              <p className="text-medium text-dark-gray line-height-30 padding-35px-bottom">
                Not all furnaces are built equal and our aim is to build a
                furnace that is catered to your specific needs. We will provide
                you with all the details regarding the various tradeoffs so that
                you have all the information you need to make a decision on the
                design of your furnace.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/images/Infographs/FurnaceFactorsInfograph.png"
                  alt="Furnace Factors Inforgraph"
                  style={{
                    width: "38rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
