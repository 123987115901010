import React from "react";

import DarkTitle from "../../DarkTitle";

export default function RefractorySelection() {
  return (
    <div>
      <DarkTitle
        title="Refractory Selection"
        tagline="general/Refractory Selection"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For a glass plant to run profitably, it is essential for its
                furnace to remain efficient for the entirety of its life cycle.
                To ensure that, it is vital to select the right refractory at
                the time of construction. Each part of the furnace has a
                different operating temperature and environment and the
                refractory has to be selected individually for different parts
                of the furnace.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                Refractory must be able to:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Sustain high temperature
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Sustain a corrosive environemtn due to the chemical
                      reactions happening in the furnace
                    </p>
                  </li>
                </ul>
              </p>

              <p className="text-medium text-dark-gray line-height-30">
                Factors to consider:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Maximum operating temperature
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Thermal shock</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Thermal expansion during heat-up
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Thermal conductivity
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Potential chemical reaction with other refractory
                    </p>
                  </li>
                </ul>
              </p>

              <p className="text-medium text-dark-gray line-height-30">
                Having build and rebuild various different furnaces, we have
                specialised in pinning down the most crucial parts of the
                furnace and optimised our refractory selection in terms of
                quality and capital expenditure. We will provide you with the
                following services:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Fully optimised refractory selection for your requirements
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Refractory vendor selection
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Chemical and physical testing of the refractory to ensure
                      quality standards are met
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
