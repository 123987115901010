import React from "react";

const fallBackQuote = `As a result of these strides in
  innovation, Tariq Glass has been able to reliably produce glass of the highest
  quality which has been accepted in over 60 countries and 6 continents. By
  partnering with us, you will be joining hands with a manufacturing consultant
  who will help set you on a path with the same trajectory and achieve all your
  goals in the glass industry.`;

export default function BlockQuote(props) {
  return (
    <section className="wow animate__fadeIn bg-light-gray padding-10px-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <i className="fas fa-quote-left text-deep-pink icon-large margin-15px-bottom"></i>
            <h5 className="text-extra-dark-gray margin-50px-bottom md-margin-20px-bottom alt-font">
              {props?.quote || fallBackQuote}
            </h5>
          </div>
          {props.children}
        </div>
      </div>
    </section>
  );
}
