import React from "react";

const styleDetails = {
  width: "100px",
  height: "100px",
  objectFit: "contain",
};
export default function ValueIcons() {
  return (
    <div>
      <section className="wow animate__fadeIn ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 last-paragraph-no-margin md-margin-30px-bottom xs-margin-15px-bottom">
              <div className="padding-50px-all lg-padding-30px-all bg-white box-shadow text-center h-100">
                <div className="padding-35px-all d-inline-block rounded-circle margin-40px-bottom sm-margin-30px-bottom bg-light-gray">
                  <img src="images/FixedFees.png" alt="" style={styleDetails} />
                </div>
                <span className="alt-font text-extra-dark-gray font-weight-600 d-block margin-20px-bottom">
                  Fixed Consultancy Fees
                </span>
                <p>
                  At the start of the project, we will mutually agree on a fixed
                  consultancy fees and all expenses related to the project will
                  be delivered to our partner at cost-price with complete
                  transparency.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 last-paragraph-no-margin md-margin-30px-bottom xs-margin-15px-bottom">
              <div className="padding-50px-all lg-padding-30px-all bg-white box-shadow text-center h-100">
                <div className="padding-35px-all d-inline-block rounded-circle margin-40px-bottom sm-margin-30px-bottom bg-light-gray">
                  <img src="images/InHouse.png" alt="" style={styleDetails} />
                </div>
                <span className="alt-font text-extra-dark-gray font-weight-600 d-block margin-20px-bottom">
                  In House Development
                </span>
                <p>
                  We have acquired a unique skill of developing a significant
                  portion of our equipment in-house so as to customise to our
                  requirements as well as reduce costs.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 last-paragraph-no-margin md-margin-30px-bottom xs-margin-15px-bottom">
              <div className="padding-50px-all lg-padding-30px-all bg-white box-shadow text-center h-100">
                <div className="padding-35px-all d-inline-block rounded-circle margin-40px-bottom sm-margin-30px-bottom bg-light-gray">
                  <img
                    src="images/InternationalVendors.png"
                    alt=""
                    style={styleDetails}
                  />
                </div>
                <span className="alt-font text-extra-dark-gray font-weight-600 d-block margin-20px-bottom">
                  International Vendors
                </span>
                <p>
                  We have significant experience in dealing with vendors from
                  all over the world and our development team is particularly
                  astute at selecting the equipment and material that is fit for
                  purpose
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
