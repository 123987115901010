import React from "react";
import Picture from "./Picture";
import ValueIcons from "./ValueIcons";

export default function Info(props) {
  return (
    <section id="about" className="big-section wow animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-11 col-12 margin-six-bottom">
            <h5 className="text-extra-dark-gray">
              We always stay on the cutting edge of the world of glass, so that
              our clients maintain their competitive advantage, backed by our
              technical expertise and insight that we have gathered over 40
              years.
            </h5>
          </div>
        </div>

        <div className="row margin-five-bottom sm-margin-ten-bottom">
          <div className="col-lg-2 col-md-2 col-12 sm-margin-15px-bottom">
            <span className="text-extra-dark-gray text-large font-weight-600">
              Our Story
            </span>
          </div>
          <div className="col-lg-10 col-md-7 col-12 last-paragraph-no-margin col-12 sm-margin-15px-bottom">
            <p className="text-medium line-height-28 w-90 sm-w-100">
              Tariq Glass kick-started the glass industry of Pakistan in 1982
              and over the course of four decades, has established itself as the
              leading glass manufacturer of the country. What started off as
              tableware plant producing 15 tons per day now produces over 1500
              tons of float glass, tableware and container glass, opal glass and
              pharmaceutical glass daily. As with any industry that has spanned
              so long, it has seen its fair share of difficulties and obstacles.
              However, in the process of overcoming these, along with its
              omnipresent vision for growth, the organisation has accumulated a
              wealth of technical knowledge and experience. It is this
              sought-after know-how that has laid the foundation of Tariq Glass
              International Solutions.
            </p>
          </div>
          <Picture
            imageLink={"images/Infographs/Timeline.png"}
            imageAlt="Timeline infographic"
          />
        </div>
        <div className="row margin-five-bottom sm-margin-ten-bottom">
          <div className="col-lg-2 col-md-2 col-12 sm-margin-15px-bottom">
            <span className="text-extra-dark-gray text-large font-weight-600">
              Our Model
            </span>
          </div>
          <div className="col-lg-10 col-md-7 col-12 last-paragraph-no-margin sm-margin-15px-bottom">
            <p className="text-medium line-height-28 w-90 sm-w-100">
              Every time Tariq Glass has ventured into a new project, the
              biggest challenge has been to sustain the economic viability while
              not compromising on the best-in-class quality standards it has
              always set for itself. It is no secret that there is a steep
              decline in quality as soon as any cost-cutting takes place and the
              current state of economic volatility has only magnified this.
              However, through our wide-ranging expertise, along with our highly
              skilled and innovative professionals, we have been able to
              continuously bridge this gap and provide world-standard glass
              while remaining highly competitive. Now, with Tariq Glass
              International Solutions, we want to help bridge this gap for all
              investors and industrialists who want to enter or expand in the
              ever-growing glass industry.
            </p>
          </div>
          <Picture
            imageLink={"images/Infographs/BridgeGap.png"}
            imageAlt="Bridging the gap infographic"
          />
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-2 col-12">
            <span className="text-extra-dark-gray text-large font-weight-600">
              Our Values
            </span>
          </div>
          <div className="col-lg-10 col-md-7 col-12 last-paragraph-no-margin sm-margin-15px-bottom">
            <p className="text-medium line-height-28 w-90 sm-w-100">
              We firmly believe that for any partnership to sustain, there has
              to be a strong element of trust and alignment of interests. It is
              for this reason that we solely work with our clients, who we
              consider our partners, and not with any seller. We believe in
              complete transparency with our partners and therefore only charge
              a fixed consultancy fee based on the extent of technology transfer
              and services required. All solutions are tailor-made to the
              requirements provided to us, keeping the budget, quality standards
              and time-frame in mind.
            </p>
            <p className="text-medium line-height-28 w-90 sm-w-100">
              <strong>
                At Tariq Glass International Solutions, we look forward to
                working with likeminded partners who believe in innovation,
                sustainability and producing world-class products.
              </strong>
            </p>
          </div>
          <ValueIcons />
        </div>
      </div>
    </section>
  );
}
