import React from "react";

const bgImage =
  "https://images.unsplash.com/photo-1579445505461-acecf2596190?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80";

export default function Header(props) {
  return (
    <section id="home" className="p-0 full-screen fit-videos position-relative">
      <div className="container position-relative full-screen">
        <div className="row h-100 align-items-center">
          <div className="col-12 col-lg-8 col-sm-9 text-center mx-auto">
            <span className="text-medium margin-20px-bottom d-block alt-font text-white-2 text-uppercase">
              {props?.tagline || "Your partner in glass"}
            </span>
            <h4 className="alt-font text-white-2 font-weight-600 text-uppercase">
              {props?.title || "tariq glass international solutions"}
            </h4>
          </div>
          <div className="down-section text-center">
            <a href="#about" className="inner-link">
              <i className="ti-arrow-down icon-extra-small text-white-2 bg-deep-pink padding-15px-all sm-padding-15px-all rounded-circle"></i>
            </a>
          </div>
        </div>
      </div>
      <video
        loop=""
        autoPlay=""
        controls=""
        muted
        className="htms-video"
        poster={props?.bgImage || bgImage}
      />
    </section>
  );
}
