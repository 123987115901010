import React from "react";

export default function Picture(props) {
  return (
    <div>
      <section className="wow animate__fadeIn big-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center wow animate__zoomIn">
              <img src={props?.imageLink} alt={props?.imageAlt} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
