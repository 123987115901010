import React from "react";
import { Link } from "react-router-dom";
const fallBackQuote = (
  <p>
    Beyond these, we provide a host of other services to help grow your glass
    plant. For our turn-key partners, we will always go a step further and
    provide special offers for whenever they require them in the future. For
    those who do not want to opt for turn-key based projects, we are more than
    happy to provide our services for their limited requirements. All services
    mentioned above and{" "}
    <Link to={"/services/float-glass"} style={{ color: "darkblue" }}>
      Float Glass
    </Link>
    ,{" "}
    <Link to={"/services/tableware"} style={{ color: "darkblue" }}>
      Tableware & Container Glass
    </Link>{" "}
    and{" "}
    <Link to={"/services/general"} style={{ color: "darkblue" }}>
      General
    </Link>{" "}
    services can be offered individually as well.
  </p>
);

export default function SmallQuote(props) {
  return (
    <section className="wow animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-14 col-xl-7 col-lg-8 col-md-10 mx-auto text-center">
            <i className="fas fa-quote-left icon-small text-deep-pink d-block margin-25px-bottom"></i>
            <h6 className="alt-font text-extra-dark-gray">
              {props?.quote || fallBackQuote}
            </h6>
            <span className="text-uppercase text-extra-small alt-font letter-spacing-3 text-medium-gray">
              {props?.name}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
