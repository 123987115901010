import React from "react";
import Header from "./Header";
import TableWareSubServices from "./TableWareSubServices.js";

const bgImage = "/images/Tableware.jpeg";

export default function TableWare() {
  return (
    <div>
      <Header
        title="Tableware & Container Glass"
        tagline="Tariq Glass International Solutions"
        bgImage={bgImage}
      />

      <TableWareSubServices />
    </div>
  );
}
