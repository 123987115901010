import React from "react";
import ParallaxScrolling from "../../ParallaxScrolling";

import DarkTitle from "../../DarkTitle";

const trainingPicture = "/images/Turnkey/BatchComposition.jpg";

export default function BatchComposition() {
  return (
    <div>
      <DarkTitle
        title="Batch Composition"
        tagline="post-production/batch composition"
        bgImage="/images/Turnkey/BatchCompositionMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Decades of R&D has led us to the point where we have fully
                optimised our batch to ensure the highest quality of glass is
                produced with minimal defects. We will provide you with our
                tried-and-tested batch composition for the different types of
                glass you want to produce.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={trainingPicture} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Composition
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                The tint and shade of the glass that is produced is directly
                controlled by the type and quality of raw materials fed into the
                furnace. The calculations behind the composition are done
                meticulously as even an extra gram of a certain chemical in the
                formula can affect the outlook of the glass being produced.
                Using our tried-and-tested batch formulas, we will help you
                produce all the different kinds of glass with absolute ease and
                minimal downtime.
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Defects
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Most defects can lead back to the quality of raw materials fed
                into the furnace. We will support you throughout the process of
                defect analysis and provide you with any fine-tuning that is
                required in the batch composition to ensure you always produce
                the highest quality glass.
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Material Handling and Storage
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Each raw material has its own handling and storage requirements
                and we will provide you with the most efficient and effective
                ways of carrying out these processes. This will ensure minimal
                wastage of resources and space while also maintaining the
                quality of glass being produced.
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Cullet
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Cullet (recycled glass) holds great value in the manufacturing
                process of glass. It can be the key to reducing our fuel
                consumption if used in the right way. At Tariq Glass
                International Solutions, we pride ourselves on using cullet in
                new and creative ways in our batch calculations to boost profits
                and be more sustainable all while maintaining our world-class
                quality.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
