import React from "react";
import { Link } from "react-router-dom";
// const bgImage = 'https://images.unsplash.com/photo-1540984332767-405cdacc3eed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80'

export default function NotFoundPage() {
  return (
    <section
      id="home"
      className="parallax p-0 mobile-height wow animate__fadeIn"
      data-parallax-background-ratio="0.5"
      style={{
        background_image: "url('https://via.placeholder.com/1920x1200')",
      }}
    >
      <div className="opacity-full bg-extra-dark-gray"></div>
      <div className="container position-relative full-screen">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center">
            <div className="bg-black-opacity-light w-50 mx-auto md-w-80 xs-w-95">
              <div className="padding-fifteen-all sm-padding-twelve-all xs-padding-25px-all">
                <span className="title-extra-large text-white-2 font-weight-600 d-block margin-30px-bottom sm-margin-10px-bottom">
                  404!
                </span>
                <h6 className="text-uppercase text-deep-pink font-weight-600 alt-font d-block margin-5px-bottom">
                  Page Not Found
                </h6>
                <Link
                  to="/"
                  className="mt-4 btn btn-transparent-white btn-medium text-extra-small border-radius-4"
                >
                  <i
                    className="ti-arrow-left margin-5px-right ms-0"
                    aria-hidden="true"
                  ></i>{" "}
                  Back To Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
