import React from "react";
// import ParallaxScrolling from "../../ParallaxScrolling";

import DarkTitle from "../../DarkTitle";

// const trainingPicture =
//   "https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2194&q=80";

export default function GlassForming() {
  return (
    <div>
      <DarkTitle
        title="Glass Forming"
        tagline="post-production/glass formikng"
        bgImage="/images/Turnkey/GlassFormingMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Specific to tableware and container glass, molten glass has to
                go through a forming machine to be moulded into the required
                shape. This is a process which requires complex machinery and
                various moving parts. Our team of experts have decades of
                experience in glass forming and will impart this know-how
                effectively to your team. The following services will be provide
                to ensure optimal glass forming:
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      A thorough training guide on different forming machines
                      including Press, Press & Blow, Spinning, I.S and PB10
                      machines
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Troubleshooting and repairing parts
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Maintenance of machines
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Overcoming defects in forming
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
