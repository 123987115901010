import React from "react";

const fallbackImage = "https://via.placeholder.com/1920x1150";

export default function ParallaxScrolling(props) {
  return (
    <section
      className="extra-big-section parallax wow animate__fadeIn"
      data-parallax-background-ratio="0.5"
      style={{
        backgroundImage: `url("${props?.bgImage || fallbackImage}")`,
      }}
    >
      <div className="container position-relative">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-7 col-md-11 text-center">
            <h5 className="text-light-gray alt-font margin-40px-bottom sm-margin-30px-bottom text-uppercase">
              {props?.heading}
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
}
