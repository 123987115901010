import React from "react";
import Tile from "./Tile";

export default function FloatGlassSubServices(props) {
  return (
    <section id="services" className="wow animate__fadeIn bg-light-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-md-8 margin-four-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
            <h5 className="alt-font text-extra-dark-gray font-weight-700 mb-0">
              Over the years, we have successfully manufactured and sold the
              following types of Float Glass:
            </h5>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 m-0 justify-content-center">
          <Tile title="Clear Glass" image="/images/FloatGlass/ClearGlass.jpg" />
          <Tile
            title="Tinted Glass"
            image="/images/FloatGlass/TintedGlass.jpg"
          />
          <Tile
            title="Coated Glass"
            image="/images/FloatGlass/CoatedGlass.jpg"
          />
          <Tile
            title="Mirror Glass"
            image="/images/FloatGlass/MirrorGlass.jpg"
          />
          <Tile
            title="Frosted Glass"
            image="/images/FloatGlass/FrostedGlass.jpg"
          />
          <Tile
            title="Automative Glass"
            image="/images/FloatGlass/AutomotiveGlass.jpg"
          />
        </div>
      </div>
    </section>
  );
}
