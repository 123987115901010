import React from "react";
import ParallaxScrolling from "../../ParallaxScrolling";

import DarkTitle from "../../DarkTitle";

const trainingPicture = "/images/Turnkey/FurnaceOperation.jpg";

export default function FuranceOperation() {
  return (
    <div>
      <DarkTitle
        title="Furnace Operation"
        tagline="post-production/furnace operation"
        bgImage="/images/Turnkey/FurnaceOperationMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For any glass plant to run profitably, the operation of a
                furnace is absolutely critical. At Tariq Glass International
                Solutions, we specialise in maintaining our melting efficiency
                over the course of our furnace’s lifetime. We will help your
                team adopt all the operating procedures we follow so that you
                can also continue to reap the benefits of an efficient furnace.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={trainingPicture} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Fuel Consumption
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Solutions for reducing fuel consumption during melting to
                      increase profitability
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Training the team in alternating the type of fuel
                      effectively depending on type of glass being produced and
                      the cost of fuel
                    </p>
                  </li>
                </ul>
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Control Systems
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Temperature control
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Pressure control
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Level control</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Flow control</p>
                  </li>
                </ul>
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Refractory
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Analysis on the state of the refractory over the
                      life-cycle of the furnace
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Repair and maintenance of refractory
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
