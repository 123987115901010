import React from "react";
import DarkTitle from "../../DarkTitle";

// const glass =
//   "https://images.unsplash.com/photo-1613970351372-9804e380bd09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470";

export default function FuranceDesign() {
  return (
    <div>
      <DarkTitle
        title="Utilities"
        // tagline="pre-production/utilities"
        bgImage="/images/Turnkey/UtilitiesMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For the smooth operation of a glass plant, the importance of
                utilities cannot be understated. As this is the last stage
                before the execution begins, we ensure that no stone is left
                unturned so that all your planning requirements are satisfied.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                The following will be provided at this stage:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Complete PFD and PID of the plant
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Complete instrumentation design of the plant
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Details of control and alarm systems
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      HAZOP and details of safety plans
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Fire prevention and protection plans
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Water supply and sewage system
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Power supply system
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Design of Nitrogen and Hydrogen plants (For Float Glass)
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      External gas supply and substations network
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Furnace oil and diesel storage and supply network
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Waste management systems
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
