import React from "react";

import DarkTitle from "../../DarkTitle";

export default function CoatedGlass() {
  return (
    <div>
      <DarkTitle
        title="Coated Glass"
        bgImage="/images/FloatGlass/CoatedGlassHeader.jpg"
        // tagline="float glass/Coated Glass"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Coated glass is a great way to add value to float glass and
                increase its profitability. This can be done in two ways:
                through Chemical Vapour Deposition (CVD) and Physical Vapour
                Deposition (PVD). At Tariq Glass Industries, we have
                successfully operated both CVD and PVD machines for a number of
                years and continue to reap the benefits of this value-addition.
                We are well-versed with all the benefits and challenges that
                these coating technologies come with and will help you make the
                right decision based on your needs and help you successfully
                construct and operate it.
              </p>
              <img
                className="container-fluid padding-50px-right"
                src="/images/Infographs/CoatingsInfograph.png"
                alt="Coating Infograph"
              />
              <p className="text-medium text-dark-gray line-height-30">
                We will provide:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      CVD reactor design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Souring and storage of gases
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Operation of CVD reactor
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Magnetron sputtering design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Paints and solvents sourcing
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Operation</p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
