import React from "react";
import Summary from "./Summary";
import ServiceIntro from "./ServiceIntro";
import Header from "./Header";
import SmallQuote from "./SmallQuote";
import FloatGlassSubServices from "./FloatGlassSubServices";

const bgImage = "/images/FloatGlass.jpeg";

export default function FloatGlass() {
  return (
    <div>
      <Header
        title="Float Glass"
        tagline="Tariq Glass International Solutions"
        bgImage={bgImage}
      />
      <ServiceIntro
        heading="Float Glass"
        text={`The float glass process, invented by Sir Alistair Pilkington in 1952, 
        revolutionised the glass industry and has made a significant contribution to the 
        world over the years. The glass that is produced through this process is used in 
        a wide range of applications including construction, automobiles and solar panels. 
        Molten glass, at about 1100℃, is continuously fed into a bath of molten tin over 
        which it floats and spreads out. Using a combination of rollers placed either side 
        of the tin bath, tight thickness control takes place to produce a glass ribbon of 
        the required thickness. The glass ribbon leaves the tin bath at about 600℃ and is 
        conveyed onto the annealing lehr where it is steadily cooled to room temperature. 
        Finally, using an automated cutting system, the ribbon is cut into sheets and 
        stored in the warehouse.`}
        listOfServices={[]}
      />

      <FloatGlassSubServices />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Melting"
        link="float-glass/melting"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Tin Bath"
        link="float-glass/tin-bath"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Annealing and Cold End"
        link="float-glass/annealing-and-cold-end"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Batch Plant"
        link="float-glass/batch-plant"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Coated Glass"
        link="float-glass/coated-glass"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Float Glass"
        root="float-glass"
        heading="Nitrogen and Hydrogen Plant"
        link="float-glass/nitrogen-and-hydrogen-plant"
        // text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <SmallQuote
        quote="Production of float glass involves multiple processes and we
        provide our solutions for each of them individually as well. As we specialise
        in each aspect of the float glass production process, we will provide our
        services to help set-up and grow your business to new heights."
      />
    </div>
  );
}
