import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const tsPreProd = "/services/turnkey/pre-production";
const tsPosProd = "/services/turnkey/post-production";
const fgLink = "/services/float-glass";

export default function NavBar() {
  return (
    <header>
      <nav className="navbar navbar-default bootsnav navbar-fixed-top header-light background-white navbar-expand-lg">
        <div className="container-lg nav-header-container">
          <div className="col-auto ps-0">
            <Link to="/" title="TGIS" className="logo">
              <img
                src="/images/Finalized.png"
                data-at2x="/images/Finalized.jpg"
                className="logo-dark"
                alt="TGIS"
                width="600"
                height="600"
              />
              <img
                src="/images/Finalized.png"
                data-at2x="/images/Finalized.jpg"
                alt="TGIS"
                className="logo-light default"
                width="600"
                height="600"
              />
            </Link>
          </div>
          <div className="col-auto col-lg accordion-menu pe-0">
            <button
              type="button"
              className="navbar-toggler collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-collapse-toggle-1"
            >
              <span className="sr-only">toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="navbar-collapse collapse justify-content-end"
              id="navbar-collapse-toggle-1"
            >
              <ul className="nav navbar-nav alt-font font-weight-700">
                <li>
                  <Link to="/" title="Home" className="inner-link">
                    Home
                  </Link>
                </li>
                <li className="dropdown simple-dropdown">
                  <HashLink to="/#services" smooth={true}>
                    Services
                  </HashLink>
                  <i
                    className="fas fa-angle-down dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-hidden="true"
                  ></i>
                  <ul className="dropdown-menu" role="menu">
                    <li className="dropdown">
                      <Link to="/services/turnkey">Turnkey</Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to={`${tsPreProd}/feasibility`}>
                            Feasibility
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/urban-and-civil-planning`}>
                            Urban & Civil Planning
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/furnace-design`}>
                            Furnace Design
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/equipment-design`}>
                            Equipment Design
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/utilities`}>Utilities</Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/construction`}>
                            Construction
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/training-programs`}>
                            Training Programs
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPreProd}/heat-up`}>Heat Up</Link>
                        </li>
                        <li>
                          <Link to={`${tsPosProd}/furnace-operation`}>
                            Furnace Operation
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPosProd}/batch-composition`}>
                            Batch Composition
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPosProd}/glass-forming`}>
                            Glass Forming
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPosProd}/maintenance`}>
                            Maintenance
                          </Link>
                        </li>
                        <li>
                          <Link to={`${tsPosProd}/reporting`}>Reporting</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <Link to="/services/float-glass">Float Glass</Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to={`${fgLink}/melting`}>Melting</Link>
                        </li>
                        <li>
                          <Link to={`${fgLink}/tin-bath`}>Tin Bath</Link>
                        </li>
                        <li>
                          <Link to={`${fgLink}/annealing-and-cold-end`}>
                            Annealing & Cold End
                          </Link>
                        </li>
                        <li>
                          <Link to={`${fgLink}/batch-plant`}>Batch Plant</Link>
                        </li>
                        <li>
                          <Link to={`${fgLink}/coated-glass`}>
                            Coated Glass
                          </Link>
                        </li>
                        <li>
                          <Link to={`${fgLink}/nitrogen-and-hydrogen-plant`}>
                            Nitrogen & Hydrogen Plant
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/services/tableware">
                        Tableware & Container Glass
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/general">General</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <HashLink to="/#about" smooth={true}>
                    About
                  </HashLink>
                </li>
                <li>
                  <HashLink to="/#contactUs" smooth={true}>
                    Contact Us
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
