import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      className="footer-strip padding-50px-tb sm-padding-30px-tb"
      id="contactUs"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 text-center text-lg-start sm-margin-20px-bottom">
            <Link to="/">
              <img
                className="footer-logo"
                src="/images/Finalized.jpg"
                data-at2x="/images/Finalized.jpg"
                alt="TGIS"
              />
            </Link>
          </div>
          <div className="col-md-6 text-center text-small text-uppercase alt-font sm-margin-10px-bottom">
            &copy; 2022 Tariq Glass International Solutions
          </div>
          <div className="col-md-3 text-center text-lg-end">
            <div className="social-icon-style-8 d-inline-block align-middle">
              <ul className="small-icon mb-0">
                <li>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/tariq-glass-industries-limited/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="email"
                    href="mailto:info@tginternationalsolutions.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-envelope"></i>
                  </a>
                </li>
                {/* <li><Link className="google" to="https://plus.google.com" target="_blank"><i className="fab fa-google-plus-g"></i></Link></li> */}
                {/* <li><Link className="dribbble" to="https://dribbble.com/" target="_blank"><i className="fab fa-dribbble me-0" aria-hidden="true"></i></Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
