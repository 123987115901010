import React from "react";
import DarkTitle from "./DarkTitle";
import ParallaxScrolling from "./ParallaxScrolling";

const discussPhoto =
  "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
const forklift = "/images/Turnkey/Feasibility/RawMaterial.jpg";
const pins =
  "https://images.pexels.com/photos/1078850/pexels-photo-1078850.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
const selection = "/images/Turnkey/Feasibility/EquipmentSelection.jpg";
const projections =
  "https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
const environment =
  "https://images.pexels.com/photos/305833/pexels-photo-305833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
const growth =
  "https://images.pexels.com/photos/5833784/pexels-photo-5833784.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";

export default function Feasibility() {
  return (
    <div>
      <DarkTitle
        title="Feasibility"
        // tagline="turnkey/pre-production"
        bgImage="/images/Turnkey/Feasibility/FeasibilityMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                An accurate and extensive feasibility report is the first step
                towards building a glass plant that can sustain its
                profitability over the years. Major decisions have to be taken
                right from the word go and our research team will help you make
                all the right ones at this pivotal stage. We will provide an
                in-depth feasibility report which will cover every aspect of the
                planning and execution stage and provide a blueprint for the
                entire project. Every possible variable will be analysed
                holistically by our team of experts and we will present a fully
                optimised plant that has been catered to your requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={discussPhoto} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Market Research
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Over the decades, we have become experts at conducting market
                research and we are well-versed with the intricacies that come
                with capturing a glass market. The current trends and
                consumption in the local glass market, import and export data as
                well as future growth forecasting will be analysed
                comprehensively to drive us in making the right decisions on the
                following factors:
                <p>
                  <ul>
                    <li>
                      <p className="hover-underline-animation">
                        Types of glass to produce
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Type of products to make
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Scale of the project (TPD)
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Markets to target
                      </p>
                    </li>
                  </ul>
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={forklift} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Raw Material Selection
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                We will survey the deposits of all the required raw materials
                within the region and use our established testing methods to
                analyse their quality. All potential vendors will be scanned
                thoroughly and a conclusion will be based on the following
                factors:
                <p>
                  <ul>
                    <li>
                      <p className="hover-underline-animation">
                        Adherence to quality standards
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Uninterrupted supply
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Suitable transport options
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Handling and storage requirements
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Duty structure in place (for imports)
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Market competitive rates
                      </p>
                    </li>
                  </ul>
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={pins} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Location
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Several potential sites will be considered before reaching a
                final decision on the location and layout of the plant based on
                the following factors:
                <p>
                  <ul>
                    <li>
                      <p className="hover-underline-animation">
                        Transport routes for raw materials
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Transport routes to potential markets
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Cost of utilities
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Freight cost (to local markets and to the port)
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Government legislation
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Potential tax benefits
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Availability of skilled work-force
                      </p>
                    </li>
                  </ul>
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={selection} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Equipment Selection
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                We will select and finalise all the equipment and machinery that
                is required for the entire process. The quality requirements and
                budget will dictate our choice of vendors and our
                well-established relationship with them will ensure you are
                provided with the best rates to minimise the capital cost. There
                will be complete transparency during negotiations will all
                potential vendors.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ParallaxScrolling bgImage={projections} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Projections
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                We value the fact that it is of utmost importance to provide any
                investor with realistic and unambiguous projections. Therefore,
                we will provide a detailed financial model which will include a
                complete analysis on the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Capital expenditure
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Major cost inputs
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Market demand and supply projections
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Future revenue and expenses projections
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Scenario based projections and sensitivity analysis
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      ROI, P&L, Cashflows, etc.
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
      <ParallaxScrolling bgImage={environment} />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Environmental Impact
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                We will provide a breakdown of all emissions that are expected
                form the plant and provide solutions to ensure they are in
                accordance with all government regulations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ParallaxScrolling bgImage={growth} />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Growth
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Over the years, we have always reaped the benefits of planning
                for expansion right from the very start. We want our partners to
                also benefit from this mind-set and therefore will pin-point
                areas where we can see potential growth in the coming years. We
                will provide provisions in our current plans to allow seamless
                expansion with minimal capital expenditure in the future.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
