import React from "react";
import ParallaxScrolling from "../../ParallaxScrolling";

import DarkTitle from "../../DarkTitle";

const trainingPicture =
  "https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80";

export default function TrainingPrograms() {
  return (
    <div>
      <DarkTitle
        title="Training Programs"
        // tagline="pre-production/training programs"
        bgImage="/images/Turnkey/TrainingMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Our aim is to provide you with a qualified and competent team
                which performs at the highest level from the very first day. To
                ensure this, we will curate personalised training programs for
                all the different departments and teams. We also go one-step
                further by starting our on-site training at Tariq Glass
                Industries before production commences. This gives a unique
                opportunity for the technical team to get hands-on with a
                running plant and allows them to be fully trained by the time
                production takes place.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ParallaxScrolling bgImage={trainingPicture} />

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Preliminary Training
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Basics of glass manufacturing
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Batch composition
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Basic quality control measures
                    </p>
                  </li>
                </ul>
              </p>
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Practical Training
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Experience the various challenges of running a glass plant
                      and learn how to solve them on a day-to-day basis
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      In-depth glass manufacturing process
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Familiarise with handling and running equipment and
                      machinery
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Defect analysis</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Warehouse management
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
