import React from "react";

import DarkTitle from "../../DarkTitle";

export default function BatchPlant() {
  return (
    <div>
      <DarkTitle
        title="Batch Plant"
        bgImage="/images/FloatGlass/BatchPlantHeader.jpg"
        // tagline="float glass/Batch Plant"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For high quality production of float glass, it is essential to
                have a batch plant which operates to the highest standards. All
                the raw materials as well as cullet (recycled glass) have to be
                fed into the furnace extremely accurately and in-time to ensure
                complete homogeneity.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                We will provide you with the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Raw material silos design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Complete conveyor and elevator design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Hopper and dolly design and construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Batch charger design and commissioning
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Control system for operation of batch plant (includes
                      weighing)
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
