const markers = [
  { markerOffset: 15, name: "India", coordinates: [78.9629, 20.5937] },
  { markerOffset: 15, name: "Nepal", coordinates: [84.124, 28.3949] },
  { markerOffset: 15, name: "Bangladesh", coordinates: [90.3563, 23.685] },
  { markerOffset: 15, name: "Ecuador", coordinates: [-78.1834, -1.8312] },
  { markerOffset: 15, name: "Colombia", coordinates: [-74.2973, 4.5709] },
  { markerOffset: 15, name: "Indonesia", coordinates: [113.9213, -0.7893] },
  {
    markerOffset: 15,
    name: " Afghanistan",
    coordinates: [67.709953, 33.93911],
  },
  { markerOffset: 15, name: " Armenia", coordinates: [45.038189, 40.069099] },
  {
    markerOffset: 15,
    name: " Argentina",
    coordinates: [-63.616672, -38.416097],
  },
  { markerOffset: 15, name: " Bulgaria", coordinates: [25.48583, 42.733883] },
  {
    markerOffset: 15,
    name: " Australia",
    coordinates: [133.775136, -25.274398],
  },
  {
    markerOffset: 15,
    name: "United States",
    coordinates: [-95.712891, 37.09024],
  },
  { markerOffset: 15, name: "Canada", coordinates: [-106.346771, 56.130366] },
  { markerOffset: 15, name: "Pakistan", coordinates: [69.34, 30.73] },
  { markerOffset: 15, name: "Egypt", coordinates: [30.8, 26.82] },
  { markerOffset: 15, name: "Uruguay", coordinates: [55.76, 32.52] },
  { markerOffset: 15, name: " Bahrain", coordinates: [50.637772, 25.930414] },
  { markerOffset: 15, name: " Burundi", coordinates: [29.918886, -3.373056] },
  { markerOffset: 15, name: " Brazil", coordinates: [-51.92528, -14.235004] },
  { markerOffset: 15, name: " Botswana", coordinates: [24.684866, -22.328474] },
  { markerOffset: 15, name: " Cameroon", coordinates: [12.354722, 7.369722] },
  { markerOffset: 15, name: " Algeria", coordinates: [1.659626, 28.033886] },
  { markerOffset: 15, name: " Spain", coordinates: [-3.74922, 40.463667] },
  { markerOffset: 15, name: " Ethiopia", coordinates: [40.489673, 9.145] },
  {
    markerOffset: 15,
    name: " United Kingdom",
    coordinates: [-3.435973, 55.378051],
  },
  { markerOffset: 15, name: " Georgia", coordinates: [43.356892, 42.315407] },
  { markerOffset: 15, name: " Ghana", coordinates: [-1.023194, 7.946527] },
  { markerOffset: 15, name: " Greece", coordinates: [21.824312, 39.074208] },
  { markerOffset: 15, name: " Iraq", coordinates: [43.679291, 33.223191] },
  { markerOffset: 15, name: " Italy", coordinates: [12.56738, 41.87194] },
  { markerOffset: 15, name: " Jordan", coordinates: [36.238414, 30.585164] },
  { markerOffset: 15, name: " Kenya", coordinates: [37.906193, -0.023559] },
  { markerOffset: 15, name: " Kuwait", coordinates: [47.481766, 29.31166] },
  { markerOffset: 15, name: " Lebanon", coordinates: [35.862285, 33.854721] },
  { markerOffset: 15, name: " Sri Lanka", coordinates: [80.771797, 7.873054] },
  { markerOffset: 15, name: " Lithuania", coordinates: [23.881275, 55.169438] },
  {
    markerOffset: 15,
    name: " New Zealand",
    coordinates: [174.885971, -40.900557],
  },
  {
    markerOffset: 15,
    name: " Madagascar",
    coordinates: [46.869107, -18.766947],
  },
  {
    markerOffset: 15,
    name: " Mauritius",
    coordinates: [57.552152, -20.348404],
  },
  { markerOffset: 15, name: " Mexico", coordinates: [-102.552784, 23.634501] },
  { markerOffset: 15, name: " Malaysia", coordinates: [101.975766, 4.210484] },
  {
    markerOffset: 15,
    name: " Mozambique",
    coordinates: [35.529562, -18.665695],
  },
  { markerOffset: 15, name: " Nigeria", coordinates: [8.675277, 9.081999] },
  { markerOffset: 15, name: " Oman", coordinates: [55.923255, 21.512583] },
  {
    markerOffset: 15,
    name: " Philippines",
    coordinates: [121.774017, 12.879721],
  },
  { markerOffset: 15, name: " Poland", coordinates: [19.145136, 51.919438] },
  {
    markerOffset: 15,
    name: " Paraguay",
    coordinates: [-58.443832, -23.442503],
  },
  { markerOffset: 15, name: " Qatar", coordinates: [51.183884, 25.354826] },
  { markerOffset: 15, name: " Romania", coordinates: [24.96676, 45.943161] },
  {
    markerOffset: 15,
    name: " Saudi Arabia",
    coordinates: [45.079162, 23.885942],
  },
  { markerOffset: 15, name: " Turkey", coordinates: [35.243322, 38.963745] },
  { markerOffset: 15, name: " Tanzania", coordinates: [34.888822, -6.369028] },
  { markerOffset: 15, name: " Ukraine", coordinates: [31.16558, 48.379433] },
  { markerOffset: 15, name: " Uganda", coordinates: [32.290275, 1.373333] },
  {
    markerOffset: 15,
    name: " Uzbekistan",
    coordinates: [64.585262, 41.377491],
  },
  { markerOffset: 15, name: " Yemen", coordinates: [48.516388, 15.552727] },
  {
    markerOffset: 15,
    name: " South Africa",
    coordinates: [22.937506, -30.559482],
  },
];

export default markers;
