import React from "react";
import DarkTitle from "../../DarkTitle";

export default function HeatUp() {
  return (
    <div>
      <DarkTitle
        title="HeatUp"
        bgImage="/images/Turnkey/HeatUpMain.jpg"
        // tagline="pre-production/heatup"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                The firing of a furnace is a meticulous process which requires
                gradual increase in temperature up to 1600°C. This process can
                take up to a month as the refractory and steel structure has to
                go through a controlled rate of expansion to ensure long-term
                safety of the furnace. For tableware and container glass plants,
                our in-house dedicated heat-up team will be taking control of
                the entire process.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                For float glass plants, we will work closely together with a
                specialist heat-up team which will fire the furnace to working
                temperature and start production. For float glass plants, this
                process will also include heating up of the tin bath using an
                electric heating system and ribbon pulling.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                Over the years, we have overseen numerous furnace firings and
                only work with the most reliable and experienced heat-up
                specialists. Therefore, we will help you select the best option
                for you and oversee the entire process so that it is carried out
                smoothly and efficiently.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
