import React from "react";

import DarkTitle from "../../DarkTitle";

export default function Techno() {
  return (
    <div>
      <DarkTitle
        title="Techno-Economic Analysis and Modelling"
        tagline="general/Techno-Economic Analysis and Modelling"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For existing manufacturers:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Accurate costing
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Sustain a corrosive environemtn due to the chemical
                      reactions happening in the furnace
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
