import React from "react";
import { Link } from "react-router-dom";

export default function Tile(props) {
  return (
    <div>
      <div className="col padding-5px-all grid-item feature-box-4 wow animate__slideInDown">
        <div className="position-relative overflow-hidden">
          <figure className="m-0">
            <img
              src={props?.image}
              alt={props?.alt || props?.title}
              style={{
                width: "800px",
                height: "571px",
                objectFit: "cover",
              }}
            />
            <div className="opacity-medium bg-extra-dark-gray"></div>
            <figcaption>
              <div className="bg-deep-pink separator-line-horrizontal-full d-inline-block margin-10px-bottom"></div>
              <span className="text-extra-large d-block text-white-2 alt-font margin-25px-bottom w-60 lg-w-100 md-margin-seven-bottom">
                {props?.title}
              </span>
            </figcaption>
            {props?.link && (
              <div>
                <Link
                  to={props?.link}
                  className="btn btn-very-small btn-white font-weight-300"
                >
                  Details
                </Link>
              </div>
            )}
          </figure>
        </div>
      </div>
    </div>
  );
}
