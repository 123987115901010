import React from "react";
// import ParallaxScrolling from "../../ParallaxScrolling";

import DarkTitle from "../../DarkTitle";

// const trainingPicture =
//   "https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2194&q=80";

export default function Maintenance() {
  return (
    <div>
      <DarkTitle
        title="Maintenance"
        tagline="post-production/Maintenance"
        bgImage="/images/Turnkey/MaintenanceMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Breakdowns have to be kept to an absolute minimum in a glass
                plant as the melting process continues regardless of the status
                of machinery downstream. Therefore, we will provide you with an
                extensive maintenance plan to ensure all your machinery is
                always fully-functioning. This will include the following
                services:
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Preventive maintenance schedules
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Corrective maintenance procedures
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Setting-up and operating back-up systems
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
