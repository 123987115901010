import React from "react";
import DarkTitle from "../../DarkTitle";
import ParallaxScrolling from "../../ParallaxScrolling";

const plant =
  "https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
export default function Urban() {
  return (
    <div>
      <DarkTitle
        title="urban and civil planning"
        // tagline="pre-production/urban and civil planning"
        bgImage="/images/Turnkey/UrbanAndCivil/UrbanAndCivil.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Topographical Survey
              </h5>
              <p className="text-medium text-dark-gray line-height-30">
                By taking a specialised urban and civil planner on board with
                us, we will also conduct a topographical survey of the land
                which will include the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Soil testing which will be the basis of all calculations
                      required for the mechanical integrity of concrete and
                      steel structures
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Site layout with exact dimensions and area calculations
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Site location plan showing all nearby roads and
                      constrlayoutuction and their respective elevations
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      High-resolution drone image
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      3-D model of the site
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
      <ParallaxScrolling bgImage={plant} />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                General Plant Layout
              </h5>
              <p className="text-medium text-dark-gray line-height-30">
                The master layout of the plant will include the following with
                grid referencing:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Layout of all production processes
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      All major buildings
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Raw material storage areas
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Office spaces</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Road network</p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
