import React from "react";

const bgImage =
  "https://images.unsplash.com/photo-1579445505461-acecf2596190?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80";

export default function DarkTitle(props) {
  return (
    <section className="wow animate__fadeIn fit-videos position-relative bg-extra-dark-gray">
      <div className="container">
        <div className="row">
          <div className="col-12 extra-small-screen page-title-medium text-center d-flex flex-column justify-content-center">
            <h1 className="alt-font text-white-2 font-weight-600 m-0 text-uppercase letter-spacing-1">
              {props?.title}
            </h1>
            <span className="d-block margin-10px-top text-white text-extra-small alt-font text-uppercase">
              {props?.tagline}
            </span>
          </div>
        </div>
        <img
          alt={props?.alt || props?.title}
          style={{ top: "70%" }}
          className="htms-video"
          src={props?.bgImage || bgImage}
        />
      </div>
    </section>
  );
}
