import React from "react";
import Tile from "./Tile";

const defaultText =
  "With Tariq Glass International Solutions, we offer turn-key consultancy for the following glass plants:";

export default function SubServices(props) {
  return (
    <section id="services" className="wow animate__fadeIn bg-light-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-md-8 margin-four-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
            <h5 className="alt-font text-extra-dark-gray font-weight-700 mb-0">
              {props?.title || defaultText}
            </h5>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 m-0 justify-content-center">
          <Tile title="Float Glass" image="/images/Turnkey/FloatGlass.jpg" />
          <Tile
            title="Container Glass"
            image="/images/Turnkey/ContainerGlass.jpg"
          />
          <Tile
            title="Tableware Glass"
            image="/images/Turnkey/TablewareGlass.jpg"
          />
        </div>
      </div>
    </section>
  );
}
