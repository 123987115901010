import React from "react";
import Summary from "./Summary";
import ServiceIntro from "./ServiceIntro";
import Header from "./Header";
import ParallaxScrolling from "./ParallaxScrolling";
// import BlockQuote from "./BlockQuote";
import SubServices from "./TurnkeySubServices";
import SmallQuote from "./SmallQuote";

const bgImage =
  "https://images.unsplash.com/photo-1557064262-6acc904bffbd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80";

export default function Turnkey() {
  return (
    <div>
      <Header
        title="Turnkey"
        tagline="Tariq Glass International Solutions"
        bgImage={bgImage}
      />
      <ServiceIntro
        showInfograph={true}
        heading="Turnkey Solutions"
        // leftHeading="Introduction"
        text="To produce high quality glass efficiently, the initial planning and design of the plant and the methods with which it is operated are equally critical. Therefore, at Tariq Glass International Solutions, using our multi-faceted expertise and experience, we provide turn-key solutions which have been tailor-made for our partners. We provide our consultancy through each step of the process including both pre-production and post-production of glass. The pre-production consultancy includes everything from the initial feasibility report to the first production of glass. This is a highly critical time as the design and construction of the furnace along with the mechanical, civil and electrical integrity of the plant will dictate how efficiently the plant will be able to operate in the future. Once the plant is in running, we will ensure high-quality and efficient production through our permanent technical support. We will provide training programs for the operational staff, site management and provide a blue-print for all control and safety checks. By opting for our turn-key solutions, our partners will be able to have the ease of simply providing their requirements, budget and timeframe and have their desired plant built for them which will run efficiently for years to come."
        // subText="With Tariq Glass International Solutions, we offer turn-key consultancy for the following glass plants:"
        listOfServices={[]}
      />

      <SubServices />

      <ParallaxScrolling
        heading="pre-production"
        bgImage="https://images.unsplash.com/photo-1513828646384-e4d8ec30d2bb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
      />

      <Summary
        section="Pre-Production"
        link="turnkey/pre-production/feasibility"
        heading="Feasibility"
        text="An accurate and extensive feasibility report is the first step towards building a glass plant that can sustain its profitability over the years. Major decisions have to be taken right from the word go and our research team will help you make all the right ones at this pivotal stage. "
      />

      <Summary
        section="Pre-Production"
        link="turnkey/pre-production/urban-and-civil-planning"
        heading="Urban and Civil Planning"
        text="Based on the conclusions of the feasibility report, a topographical survey and general plant layout will be prepared by us. These findings and drawings will be the foundation for us to use the land in the most efficient and effective way possible."
      />

      <Summary
        section="Pre-Production"
        link="turnkey/pre-production/furnace-design"
        heading="Furnace Design"
        text="As the heart of any glass plant, it is absolutely essential to have a furnace design that is fit-for-purpose. By using our tried and tested database of drawings and incorporating the latest advancements in technology, we will provide you with detailed furnace drawings that will match all your melting requirements."
      />

      <Summary
        link="turnkey/pre-production/equipment-design"
        section="Pre-Production"
        heading="Equipment Design"
        text="Once the furnace design has been finalised, we will provide detailed engineering drawings of the required machinery and equipment that is needed for all upstream and downstream production processes. The sub-suppliers will also be finalised at this stage and all negotiations will be done with complete transparency to ensure you get the best value for money."
      />

      <Summary
        link="turnkey/pre-production/utilities"
        section="Pre-Production"
        heading="Utilities"
        text="For the smooth operation of a glass plant, the importance of utilities cannot be understated. As this is the last stage before the execution begins, we ensure that no stone is left unturned so that all your planning requirements are satisfied."
      />

      <Summary
        section="Pre-Production"
        link="turnkey/pre-production/construction"
        heading="Construction"
        text="Once all the planning has been complete, all subcontractors will go through a rigorous selection process. After finalising the sub-contractors, our dedicated project management team will ensure all construction taking place is of the highest standard and that all timelines are strictly being met."
      />

      <Summary
        link="turnkey/pre-production/training-programs"
        section="Pre-Production"
        heading="Training Programs"
        text="Our aim is to provide you with a qualified and competent team which performs at the highest level from the very first day. To ensure this, we will curate personalised training programs for all the different departments and teams. We also go one-step further by starting our on-site training at Tariq Glass Industries before production commences. This gives a unique opportunity for the technical team to get hands-on with a running plant and allows them to be fully trained by the time production takes place."
      />

      <Summary
        link="turnkey/pre-production/heat-up"
        section="Pre-Production"
        heading="Heat-Up"
        text="At the final step, we will work closely together with a specialist heat-up team which will fire the furnace to working temperature and start production."
      />

      <ParallaxScrolling
        bgImage="https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80"
        heading="post-production"
      />

      <SmallQuote
        quote="After the furnace has reached working temperature and we have the first flow of glass, your technical team will already be adequately trained to operate a glass 
        plant thanks to our production training program. However, we will always go a step further for our partners and be a pillar of support for them for the years to come."
      />

      <Summary
        link="turnkey/post-production/furnace-operation"
        section="Post-Production"
        heading="Furnace Operation"
        text="For any glass plant to run profitably, the operation of a furnace is absolutely critical. At Tariq Glass International Solutions, we specialise in maintaining our melting efficiency over the course of our furnace’s lifetime. We will help your team adopt all the operating procedures we follow so that you can also continue to reap the benefits of an efficient furnace. We will work closely together with a specialist heat-up team which will fire the furnace to working temperature and start production."
      />

      <Summary
        section="Post-Production"
        heading="Batch Composition"
        link="turnkey/post-production/batch-composition"
        text="Decades of R&D has led us to the point where we have fully optimised our batch to ensure the highest quality of glass is produced with minimal defects. We will provide you with our tried-and-tested batch composition for the different types of glass you want to produce."
      />

      <Summary
        link="turnkey/post-production/glass-forming"
        section="Post-Production"
        heading="Glass Forming"
        text="Specific to tableware and container glass, molten glass has to go through a forming machine to be moulded into the required shape. This is a process which requires complex machinery and various moving parts. Our team of experts have decades of experience in glass forming and will impart this know-how effectively to your team."
      />

      <Summary
        link="turnkey/post-production/maintenance"
        section="Post-Production"
        heading="Maintenance"
        text="Breakdowns have to be kept to an absolute minimum in a glass plant as the melting process continues regardless of the status of machinery downstream. Therefore, we will provide you with an extensive maintenance plan to ensure all your machinery is always fully-functioning."
      />

      <Summary
        link="turnkey/post-production/reporting"
        section="Post-Production"
        heading="Reporting"
        text="To guarantee long term growth, we will provide the team with a framework of reporting standards which will encompass all areas of the plant. By adopting these reporting protocols, you will be able to have an overview of how well your plant is running at just a glance while also being able to look deeply into any area if you wish."
      />

      <SmallQuote />
    </div>
  );
}
