import React from "react";
import SmallQuote from "./SmallQuote";
import Tile from "./Tile";

export default function GeneralSubServices(props) {
  return (
    <section id="services" className="wow animate__fadeIn bg-light-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-md-8 margin-four-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
            <h5 className="alt-font text-extra-dark-gray font-weight-700 mb-0">
              We provide the following general services:
            </h5>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-xl-4 row-cols-md-2 m-0 justify-content-center">
          <Tile
            title="Batch & Cullet Composition"
            image="/images/General/BatchComposition.jpg"
          />
          <Tile
            title="Raw Material Processing"
            image="/images/General/RawMaterialProcessing.jpg"
          />
          <Tile
            title="Refractory Selection"
            image="/images/General/RefractorySelection.jpg"
          />
          <Tile
            title="Defect Analysis & Remedies"
            image="/images/General/DefectAnalysis.jpg"
          />
          <Tile
            title="Sustainability"
            image="/images/General/Sustainability.jpg"
          />
          <Tile
            title="Vendor Selection"
            image="/images/General/VendorSelection.jpg"
          />
          <Tile
            title="Project Management"
            image="/images/General/ProjectManagement.jpg"
          />
          <Tile
            title="Techno-Economic Analysis & Modelling"
            image="/images/General/TechnoEconomic.jpg"
          />
          <Tile
            title="Electric Power Supply"
            image="/images/General/ElectricPowerSupply.jpg"
          />
          <Tile
            title="Furnace Inspection & Repair"
            image="/images/General/FurnaceInspection.jpg"
          />
          <Tile title="Audits" image="/images/General/Audit.jpg" />
          <Tile
            title="Shutdown & Rebuild"
            image="/images/General/Shutdown.jpg"
          />
        </div>
        <SmallQuote
          quote="With large scale glass manufacturing, there are several moving parts which 
          need to run smoothly for optimal production. We provide our consultancy to ensure 
          this so that your plant is always running efficiently and profitably."
        />
      </div>
    </section>
  );
}
