import React from "react";
import Header from "./Header";
import GeneralSubServices from "./GeneralSubServices";

const bgImage =
  "https://images.unsplash.com/photo-1598302936625-6075fbd98dd7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80";

export default function General() {
  return (
    <div>
      <Header
        title="General"
        tagline="Tariq Glass International Solutions"
        bgImage={bgImage}
      />

      <GeneralSubServices />
    </div>
  );
}
