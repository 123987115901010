import React from "react";

import DarkTitle from "../../DarkTitle";

export default function Furnace() {
  return (
    <div>
      <DarkTitle
        title="Furnace"
        tagline="tableware and container glass/furnace"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Container and Tableware furnaces are usually end-fired furnaces
                with capacities ranging from 30 TPD to 500 TPD. These furnaces
                are fitted with a series of burners and a regenerators is placed
                at the back of the furnace to provide the heat recuperation
                system. The flames produced in end-fired furnaces are usually
                longer (compared to cross-fired) and the control of the
                temperature profile is easier therefore making it more suitable
                for the production of container and tableware glass.
                Furthermore, the batch can also be heated by an electric
                boosting system which further improves the melting capacity.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                Not all furnaces are built equal and our aim is to build a
                furnace that is catered to your specific needs. We will provide
                you with all the details regarding the various tradeoffs so that
                you have all the information you need to make a decision on the
                design of your furnace.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                Factors: Capital cost, type of glass we want to focus on,
                quality of glass we require, operational cost, melting
                efficiency, future plans Solutions: Design of the furnace,
                refractory selection, provisions
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                <strong>
                  Once your requirements have been analysed and the decisions
                  have been made, we will provide you with all the technical
                  drawings of the furnace and oversee its construction.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
