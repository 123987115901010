import React from "react";
import { Link } from "react-router-dom";

const serviceImages = {
  turnkey:
    "https://images.unsplash.com/photo-1557064262-6acc904bffbd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
  floatglass: "/images/FloatGlass.jpeg",
  tableware: "/images/Tableware.jpeg",
  general:
    "https://images.unsplash.com/photo-1598302936625-6075fbd98dd7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
};

export default function Services() {
  return (
    <section id="services" className="wow animate__fadeIn bg-light-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-md-8 margin-four-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
            <h5 className="alt-font text-uppercase text-extra-dark-gray font-weight-700 mb-0">
              Services
            </h5>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-xl-4 row-cols-md-2 m-0 justify-content-center">
          <div className="col padding-5px-all grid-item feature-box-4 wow animate__slideInDown">
            <div className="position-relative overflow-hidden">
              <figure className="m-0">
                <img
                  src={serviceImages.turnkey}
                  alt=""
                  style={{
                    width: "800px",
                    height: "571px",
                    objectFit: "cover",
                  }}
                />
                <div className="opacity-medium bg-extra-dark-gray"></div>
                <figcaption>
                  {/* <span className="text-medium-gray margin-10px-bottom d-inline-block ">
                    Turnkey
                  </span> */}
                  <div className="bg-deep-pink separator-line-horrizontal-full d-inline-block margin-10px-bottom"></div>
                  <span className="text-extra-large d-block text-white-2 alt-font margin-25px-bottom w-60 lg-w-100 md-margin-seven-bottom">
                    Turnkey
                  </span>
                  <Link
                    to="/services/turnkey"
                    className="btn btn-very-small btn-white font-weight-300"
                  >
                    Details
                  </Link>
                </figcaption>
              </figure>
            </div>
          </div>
          <div
            className="col padding-5px-all grid-item feature-box-4 wow animate__slideInDown"
            data-wow-delay="0.2s"
          >
            <div className="position-relative overflow-hidden">
              <figure className="m-0">
                <img
                  src={serviceImages.floatglass}
                  alt=""
                  style={{
                    width: "800px",
                    height: "571px",
                    objectFit: "cover",
                  }}
                />
                <div className="opacity-medium bg-extra-dark-gray"></div>
                <figcaption>
                  {/* <span className="text-medium-gray margin-10px-bottom d-inline-block ">
                    Float Glass
                  </span> */}
                  <div className="bg-deep-pink separator-line-horrizontal-full d-inline-block margin-10px-bottom"></div>
                  <span className="text-extra-large d-block text-white-2 alt-font margin-25px-bottom w-60 lg-w-100 md-margin-seven-bottom">
                    Float Glass
                  </span>
                  <Link
                    to="/services/float-glass"
                    className="btn btn-very-small btn-white font-weight-300"
                  >
                    Details
                  </Link>
                </figcaption>
              </figure>
            </div>
          </div>
          <div
            className="col padding-5px-all grid-item feature-box-4 wow animate__slideInDown"
            data-wow-delay="0.2s"
          >
            <div className="position-relative overflow-hidden">
              <figure className="m-0">
                <img
                  src={serviceImages.tableware}
                  alt=""
                  style={{
                    width: "800px",
                    height: "571px",
                    objectFit: "cover",
                  }}
                />
                <div className="opacity-medium bg-extra-dark-gray"></div>
                <figcaption>
                  {/* <span className="text-medium-gray margin-10px-bottom d-inline-block ">
                    Tableware and Container Glass
                  </span> */}
                  <div className="bg-deep-pink separator-line-horrizontal-full d-inline-block margin-10px-bottom"></div>
                  <span className="text-extra-large d-block text-white-2 alt-font margin-25px-bottom w-60 lg-w-100 md-margin-seven-bottom">
                    Tableware and Container Glass
                  </span>
                  <Link
                    to="/services/tableware"
                    className="btn btn-very-small btn-white font-weight-300"
                  >
                    Details
                  </Link>
                </figcaption>
              </figure>
            </div>
          </div>
          <div
            className="col padding-5px-all grid-item feature-box-4 wow animate__slideInDown"
            data-wow-delay="0.4s"
          >
            <div className="position-relative overflow-hidden">
              <figure className="m-0">
                <img
                  src={serviceImages.general}
                  alt=""
                  style={{
                    width: "800px",
                    height: "571px",
                    objectFit: "cover",
                    transform: "scaleX(-1)",
                  }}
                />
                <div className="opacity-medium bg-extra-dark-gray"></div>
                <figcaption>
                  {/* <span className="text-medium-gray margin-10px-bottom d-inline-block ">
                    General
                  </span> */}
                  <div className="bg-deep-pink separator-line-horrizontal-full d-inline-block margin-10px-bottom"></div>
                  <span className="text-extra-large d-block text-white-2 alt-font margin-25px-bottom w-60 lg-w-100 md-margin-seven-bottom">
                    General
                  </span>
                  <Link
                    to="/services/general"
                    className="btn btn-very-small btn-white font-weight-300"
                  >
                    Details
                  </Link>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
