import React from "react";
import DarkTitle from "../../DarkTitle";

export default function VendorSelection() {
  return (
    <div>
      <DarkTitle title="Vendor Selection" tagline="general/Vendor Selection" />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                <strong>Outsourced: </strong>civil work, building material,
                steel, raw materials, machinery, sub-contractors,
                instrumentation, utilities, fuel
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                How we will help you outsource:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Have experience of working with vendors for more than x
                      countries
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      As a manufacturer of glass ourselves, we are well versed
                      with the required quality and market value of all items
                      and therefore will provide you with the best options
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Complete transparency during negotiations with vendors
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      We work solely with you and not with any supplier
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
