import React from "react";
import DarkTitle from "../../DarkTitle";
// import ParallaxScrolling from "../../ParallaxScrolling";

// const glass =
//   "https://images.unsplash.com/photo-1613970351372-9804e380bd09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470";

export default function EquipmentDesign() {
  return (
    <div>
      <DarkTitle
        title="Equipment Design"
        // tagline="pre-production/equipment design"
        bgImage="/images/Turnkey/EquipmentDesign/EquipmentDesign.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Once the furnace design has been finalised, we will provide
                detailed engineering drawings of the required machinery and
                equipment that is needed for all upstream and downstream
                production processes. The sub-suppliers will also be finalised
                at this stage and all negotiations will be done with complete
                transparency to ensure you get the best value for money.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                For our turn-key plants, the major equipment design will
                include:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Tin Bath design (Float Glass)
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Annealing lehr design
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Cold end design</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Batch plant design
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Forming machine specifications (Tableware and Container
                      Glass)
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Forehearth design (Tableware and Container Glass)
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
