import React from "react";
import DarkTitle from "../../DarkTitle";

// const glass =
//   "https://images.unsplash.com/photo-1613970351372-9804e380bd09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470";

export default function Construction() {
  return (
    <div>
      <DarkTitle
        title="Construction"
        // tagline="pre-production/construction"
        bgImage="/images/Turnkey/ConstructionMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Once all the planning has been complete, all subcontractors will
                go through a rigorous selection process. After finalising the
                sub-contractors, our dedicated project management team will
                ensure all construction taking place is of the highest standard
                and that all timelines are strictly being met.
                <p />
                <p className="text-medium text-dark-gray line-height-30">
                  Our project management team will take the responsibility for
                  the following:
                  <ul>
                    <li>
                      <p className="hover-underline-animation">
                        Selection of all sub-contractors and finalising their
                        contracts
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Oversee layout of civil foundations
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Oversee consturction of buildings, furnace and tin bath
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Installation of machinery and equipment
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Minimise wastage of material
                      </p>
                    </li>
                    <li>
                      <p className="hover-underline-animation">
                        Ensure all timelines are met
                      </p>
                    </li>
                  </ul>
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
