import React from "react";

export default function ServiceIntro(props) {
  return (
    <section id="about" className="big-section wow animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-11 col-12 margin-six-bottom">
            <h5 className="text-extra-dark-gray">{props?.heading}</h5>
          </div>
        </div>
        <div className="row margin-five-bottom sm-margin-ten-bottom">
          <div className="col-lg-2 col-md-2 col-12 sm-margin-15px-bottom">
            <span className="text-extra-dark-gray text-large font-weight-600">
              {props?.leftHeading}
            </span>
          </div>
          <div className="col-lg-10 col-md-7 col-12 last-paragraph-no-margin col-12 sm-margin-15px-bottom">
            <p className="text-medium line-height-28 w-90 sm-w-100">
              {props?.text}
            </p>
            <p className="text-medium line-height-28 w-90 sm-w-100">
              <b>
                <u>{props?.subText}</u>
              </b>
            </p>
            <div className="margin-15px-top margin-20px-left">
              {props?.listOfServices?.map((x) => (
                <div className="margin-10px-top">
                  <ul className="text-medium p-md-0 list-style-2">
                    <li>{x}</li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        {props.showInfograph && (
          <img
            className="container-fluid padding-50px-right"
            src="/images/Infographs/TurnkeyInfograph.png"
            alt="Bridging the gap infographic"
          />
        )}
      </div>
    </section>
  );
}
