import React from "react";

import DarkTitle from "../../DarkTitle";

export default function Melting() {
  return (
    <div>
      <DarkTitle
        title="Melting"
        bgImage="/images/Tableware/Melting.jpg"
        // tagline="float glass/melting"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                Float glass furnaces are cross-fired furnaces with capacities
                ranging from 250 TPD to 1200 TPD. These furnaces are fitted with
                a series of burners and a pair of regenerators are placed either
                side of the furnace to provide the heat recuperation system. The
                batch is added onto the top of the molten glass and melted using
                the heat from the flames as well as the molten glass below. The
                batch undergoes a series of chemical reactions during melting
                and releases a large amount of gases which get trapped in the
                form of bubbles. These bubbles are removed using fining agents
                and the homogenous melt is gradually cooled before it leaves the
                furnace and enters the tin bath.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <h5 className="text-extra-dark-gray alt-font font-weight-600">
                Decision Making
              </h5>
              <p className="text-medium line-height-30 text-dark-gray">
                Not all furnaces are built equal and our aim is to build a
                furnace that is catered to your specific needs. We will provide
                you with all the details regarding the various tradeoffs so that
                you have all the information you need to make a decision on the
                design of your furnace. Once your requirements have been
                analysed and the decisions have been made, we will provide you
                with all the technical drawings of the furnace and oversee its
                construction.
              </p>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/images/Infographs/FurnaceFactorsInfograph.png"
                  alt="Furnace Factors Inforgraph"
                  style={{
                    width: "38rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
