import React from "react";
import { Link } from "react-router-dom";

export default function Summary(props) {
  return (
    <section className="wow animate__fadeIn border-top border-width-1 border-color-medium-gray big-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-2 col-lg-3 wow animate__fadeInLeft md-margin-5px-bottom">
            <div className="separator-line-horrizontal-full bg-deep-pink margin-20px-bottom d-none d-lg-block"></div>
            <span className="text-uppercase alt-font text-medium-gray letter-spacing-1 font-weight-600 text-small">
              {props?.section}
            </span>
          </div>
          <div className="col-12 col-xl-8 col-lg-7 offset-lg-2 last-paragraph-no-margin">
            <h5 className="alt-font text-extra-dark-gray font-weight-600 margin-30px-bottom">
              {props?.heading}
            </h5>
            <p className="text-medium line-height-30">{props?.text}</p>
            <Link
              to={`/services/${props?.link}`}
              className="btn btn-dark-gray btn-medium margin-30px-top"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
