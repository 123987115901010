import React from "react";

import DarkTitle from "../../DarkTitle";

export default function NitrogenAndHydrogenPlant() {
  return (
    <div>
      <DarkTitle
        title="Nitrogen and Hydrogen Plant"
        bgImage="/images/FloatGlass/NitrogenHydrogenHeader.jpg"
        // tagline="float glass/Nitrogen and Hydrogen Plant"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                For the tin bath to operate smoothly, it is essential for the
                atmosphere within it to remain completely inert and void of any
                oxygen. This is to make sure the tin does not react with the
                oxygen and form oxides which contaminates the glass ribbon and
                can potentially disrupt its flow. Therefore, to ensure a
                continuous flow of Nitrogen and Hydrogen gas into the tin bath,
                it is essential to produce these gases in-house.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                We will provide you with the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Help you select the best manufacturers based on our years
                      of experience within the industry
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Oversee the installation of the plants
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Complete instrumentation of the plant
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Operation and safety control
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
