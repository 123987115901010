import React from "react";
import Header from "./Header";
import Info from "./Info";
import BlockQuote from "./BlockQuote";
import Services from "./Services";
import MapChart from "./Map";

export default function Home(props) {
  return (
    <div>
      <Header
        title="TARIQ GLASS INTERNATIONAL SOLUTIONS"
        tagline="Your partner in glass"
      />
      <Services />
      <Info />
      <BlockQuote>
        <MapChart />
      </BlockQuote>
    </div>
  );
}
