import React from "react";

import DarkTitle from "../../DarkTitle";
export default function Reporting() {
  return (
    <div>
      <DarkTitle
        title="Reporting"
        tagline="post-production/reporting"
        bgImage="/images/Turnkey/ReportingMain.jpg"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                To guarantee long term growth, we will provide the team with a
                framework of reporting standards which will encompass all areas
                of the plant. By adopting these reporting protocols, you will be
                able to have an overview of how well your plant is running at
                just a glance while also being able to look deeply into any area
                if you wish. This will include the following services:
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Help you develop systems in every department
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Provide you with internationally accepted standards to
                      ensure the plant is always being run in the desired manner
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Provide you with a list of checks and balances to ensure
                      that you always have an overview of the operation of the
                      plant
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
