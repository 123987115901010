import React from "react";

import DarkTitle from "../../DarkTitle";

export default function TinBath() {
  return (
    <div>
      <DarkTitle
        title="Tin Bath"
        bgImage="/images/FloatGlass/TinBathHeader.jpg"
        // tagline="float glass/tin bath"
      />
      <section className="wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 last-paragraph-no-margin">
              <p className="text-medium text-dark-gray line-height-30">
                The introduction of a tin bath truly revolutionised the flat
                glass industry by allowing large scale production with strict
                thickness control. Once the molten glass leaves the furnace, it
                enters the tin bath through the lip stone and spreads out over
                the molten tin. As tin is denser than glass, the molten glass
                floats over the tin and forms a ribbon. Using an automated top
                roller system, the thickness of the ribbon is controlled to
                achieve the required thickness.
              </p>
              <p className="text-medium text-dark-gray line-height-30">
                As a leading manufacturer ourselves, we understand how crucial
                it is to have a tin bath which operates efficiently and
                reliably. Once you give us your desired tonnage of production,
                we will provide you with the following:
                <ul>
                  <li>
                    <p className="hover-underline-animation">
                      Technical drawing of the tin bath
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Technical drawing of the roof and heating elements
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Top roller design
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Dross box design
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Refractory selection
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">Tin sourcing</p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Overseeing construction
                    </p>
                  </li>
                  <li>
                    <p className="hover-underline-animation">
                      Operation control
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
