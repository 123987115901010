import React from "react";
import SmallQuote from "./SmallQuote";
import Tile from "./Tile";

export default function TableWareSubServices(props) {
  return (
    <section id="services" className="wow animate__fadeIn bg-light-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-md-8 margin-four-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
            <h5 className="alt-font text-extra-dark-gray font-weight-700 mb-0">
              We provide the following services for tableware and container
              glass:
            </h5>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 m-0 justify-content-center">
          <Tile title="Melting" image="/images/Tableware/Melting.jpg" />
          <Tile title="Forming" image="/images/Tableware/Forming.jpg" />
          <Tile
            title="Mould Design & Manufacturing"
            image="/images/Tableware/Mould.jpg"
          />
          <Tile
            title="Electric Boosting System"
            image="/images/Tableware/ElectricBoosting.jpg"
          />
          <Tile
            title="Decoration & Value Addition"
            image="/images/Tableware/Decoration.jpg"
          />
          <Tile
            title="Oxygen Plant"
            image="/images/Tableware/OxygenPlant.jpg"
          />
        </div>
        <SmallQuote
          quote="We provide a host of services in the tableware and container 
          production process and help increase your profitability and competitive edge 
          through various means of value-addition and improving melting and production 
          efficiency."
        />
      </div>
    </section>
  );
}
